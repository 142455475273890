import { createContext } from "react";

const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const PhoneContext = createContext(initialstate);
PhoneContext.displayName = "PhoneContext";

export default PhoneContext;
